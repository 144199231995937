export interface Invoice {
  id: number;
  uuid: string;
  pdf_invoice: string;
  invoice_datetime: string;
  subtotal: number;
  total: number;
  chargeback: boolean;
  btw: number;
  merchant_reference: string;
  payment: Payment;
}

export interface Payment {
  id: number;
  uuid: string;
  payment_amount: number;
  payable_price: string;
  currency_code: string;
  shopper_email: string;
  shopper_reference: string;
  merchant_signature: string;
  merchantReference: string;
  status: PaymentResponse;
  approved: boolean;
  paid_at: string;
  authorized_at: string;
  origin_url?: string;
  finalized_payment?: {
    id: number;
    uuid: string;
    invoice_datetime: string;
    pdf_invoice: any;
  };
}

export enum PaymentResponse {
  OPEN = 'open',
  AUTHORIZED = 'authorized',
  REFUSED = 'refused',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
  ERROR = 'error'
}
