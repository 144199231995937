import VueRouter from 'vue-router';
import axios from 'axios';
import VueI18n from 'vue-i18n';
import { Notify } from 'quasar';
import { EXTERNAL_RESOURCES, RESOURCES } from '@ligo/dashboard/customer/store';
import {
  TokenService,
  AuthToken,
  TokenInterface,
  ApiService
} from '@ligo/shared/utils';
import { AuthResources, DefaultUser } from '../models';

class AuthenticationError extends Error {
  constructor(public errorCode, public message, public data) {
    super(message);
    this.name = this.constructor.name;
    this.data = data;
  }
}

function cleanData(): void {
  TokenService.removeToken();
  ApiService.removeHeader();
  ApiService.unmount401Interceptor();
}

async function checkHybridRedirection(memberData) {
  if (memberData.data.is_hybrid) {
    const response = await ApiService.auth_redirection(
      EXTERNAL_RESOURCES.AUTHENTICATED_REDIRECTION(
        RESOURCES.OLD_CUSTOMER_DASHBOARD
      )
    );
    TokenService.removeToken();
    ApiService.removeHeader();
    ApiService.unmount401Interceptor();
    await window.location.assign(response.request.responseURL);
    return true;
  }
}

export const userServiceFactory = (
  router: VueRouter,
  resources: AuthResources,
  i18n: VueI18n
) => ({
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * @throws AuthenticationError
   **/
  auth: async function (data: any, registration: boolean): Promise<any> {
    const message = registration ? 'alerts.register.' : 'alerts.login.';
    try {
      cleanData();
      const url = registration ? resources.REGISTER : resources.LOGIN;
      const response = await axios.post(url, data);
      const token = new AuthToken(response);
      token.save();
      ApiService.setAuth();
      const meResponse: any = await ApiService.get(resources.ME);
      const hybrid = await checkHybridRedirection(meResponse);

      if (!hybrid) {
        token.userHash = meResponse.data.user_hash;
        token.save();
        const notification = i18n.t(`${message}success`) as string;
        Notify.create({
          message: notification,
          color: 'dark',
          position: 'top',
          icon: 'mdi-emoticon'
        });
        return token;
      }
    } catch (error) {
      const errorCode: string = error.response.data.error_code;
      const errorMessage: string = registration
        ? error.response.data.errors.full_messages[0]
        : error.response.data.errors[0];
      if (registration) {
        Notify.create({
          message: errorMessage,
          color: 'negative',
          position: 'top',
          icon: 'mdi-alert'
        });
      }

      throw new AuthenticationError(
        errorCode,
        errorMessage,
        error.response.data
      );
    }
  },
  async redirection_auth(data: TokenInterface) {
    cleanData();
    const token = new AuthToken(undefined, data);
    token.save();
    ApiService.setAuth();
    const response: any = await ApiService.get(resources.ME);
    token.firstname = response.data.firstname;
    token.lastname = response.data.lastname;
    token.email = response.data.email;
    token.userHash = response.data.user_hash;
    token.userUuid = response.data.uuid;
    token.save();
    ApiService.setAuth();
    return token;
  },
  confirm: async function (token: any): Promise<boolean> {
    try {
      await ApiService.post(resources.CONFIRMATION, {
        token: token
      });
      return true;
    } catch (error) {
      return false;
    }
  },
  resetPassword: async function (data: any) {
    const response = ApiService.post(resources.RESET_PASSWORD, data);
    return response;
  },
  changePassword: async function (data: any) {
    const response = ApiService.post(resources.PASSWORD_CONFIRM, data);
    return response;
  },

  me: async function () {
    const response = await ApiService.get<DefaultUser>(resources.ME);
    const user: DefaultUser = {
      id: response.data.id,
      firstname: response.data.firstname,
      lastname: response.data.lastname,
      phone: response.data.phone,
      email: response.data.email,
      profile_image: response.data.profile_image,
      uuid: response.data.uuid,
      membership_type_id: response.data.membership_type_id,
      locale: response.data.locale,
      membership: response.data.membership,
      is_hybrid: response.data.is_hybrid,
      did_receive_contract_for_free:
        response.data.did_receive_contract_for_free,
      fast_incorporation: response.data.fast_incorporation,
      confirmed_at: response.data.confirmed_at,
      downgrade_date: response.data.downgrade_date
    };
    return user;
  },

  /**
   * Logout the current user by removing the token from storage.
   **/
  async logout() {
    const response = await ApiService.delete(resources.LOGOUT).catch(
      (error) => {
        console.log('__LOGOUT ERROR', error);
      }
    );
    cleanData();
    return response;
  },

  interceptCallback() {
    const logout = this.logout;
    return (error: any) => {
      if (error.request.status == 401) {
        ApiService.removeHeader();
        TokenService.removeToken();
        void router.push({ name: 'login' });
        logout();
      }
      throw error;
    };
  }
});

export type UserService = ReturnType<typeof userServiceFactory>;
