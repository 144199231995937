import { ApiService, createGetService } from '@ligo/shared/utils';
import { RESOURCES } from '../resources';
import { Payment } from '../models/payment';

export const PaymentService = {
  ...createGetService<Payment>(RESOURCES.ALL_PAYMENTS),
  async applyCoupon(paymentId: string, couponCode: string) {
    return ApiService.post(`${RESOURCES.PAYMENT(paymentId)}/apply_coupon`, {
      code: couponCode
    })
      .then(() => {
        return 'success';
      })
      .catch((error) => {
        return error.code == 403 ? 'no_product' : 'generic';
      });
  },
  async removeCoupon(paymentId: string) {
    return ApiService.patch(
      `${RESOURCES.PAYMENT(paymentId)}/revert_coupon`,
      {}
    );
  }
};
