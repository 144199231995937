import { ActionHandler } from '@ligo/shared/utils';

export type AuthResources = {
  ME: string;
  REGISTER: string;
  LOGOUT: string;
  LOGIN: string;
  CONFIRMATION: string;
  RESET_PASSWORD: string;
  PASSWORD_CONFIRM: string;
};

export interface DefaultUserInfo {
  data: {
    email: string;
    firstname: string;
    lastname: string;
  };
}

export interface AuthError {
  code: string;
  message: string;
  data: any;
}

export interface DefaultUser {
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  password?: string;
  password_confirmation?: string;
  id: string;
  profile_image: string;
  uuid: string;
  street?: string;
  house_number?: string;
  zip?: string;
  membership_type_id?: number;
  locale?: string;
  membership?: Membership;
  is_hybrid?: boolean;
  did_receive_contract_for_free: boolean;
  fast_incorporation: boolean;
  confirmed_at: string;
  downgrade_date?: string;
}

export interface Membership {
  from: string;
  id: number;
  membership_type: MembershipType;
  to: string;
  price: string;
  uuid: string;
  available_signatures: number;
  available_templates: number;
  next_billing_date?: string;
}

export interface MembershipType {
  billing_interval: string;
  id: number;
  level: MembershipTypeLevel;
  name: string;
  price: string;
  slug: string;
  uuid: string;
  signatures: number;
  templates: number;
  description: any;
}

export enum MembershipTypeLevel {
  FREE = 'free',
  TRIAL = 'trial',
  BASIC = 'basic',
  PREMIUM = 'premium',
  ACCOUNTANT = 'accountant',
  FEATURES = 'features',
  NOTARY = 'notary'
}

export interface AuthActionHandlers {
  onLogout?: ActionHandler;
  onAuth?: ActionHandler;
}

export interface SocialBtn {
  icon: string;
  label: string;
  link: string;
}

export interface LoginTexts {
  userPlaceholder: string;
  userLabel: string;
  passPlaceholder: string;
  passLabel: string;
}

export interface ResetTexts {
  userPlaceholder?: string;
  userLabel?: string;
  passPlaceholder?: string;
  passLabel?: string;
  confirmPlaceholder?: string;
  confirmLabel?: string;
}
