import { ApiService } from './api.service';
import { Dictionary } from '../tools/types';

interface ListResult<T> {
  data: T[];
}

const accumulateQueryParams = (params: Dictionary) => (
  acc: string,
  curr: string,
  i: number
) => {
  const and = i !== 0 ? '&' : '';
  const keyValue = `${curr}=${params[curr]}`;
  return acc + and + keyValue;
};

export const buildQuery = (params: Dictionary): string => {
  return Object.keys(params).length > 0
    ? `?${Object.keys(params).reduce(accumulateQueryParams(params), '')}`
    : '';
};

export const createGetService = <R>(url: string, service = ApiService) => ({
  getById(id: string) {
    return service.get<R>(`${url + id}/`);
  },
  list(query: Dictionary = {}) {
    return service.get<R[]>(url, query);
  }
});

export type GetService = ReturnType<typeof createGetService>;

export const createCRUDService = <T, R = T>(
  url: string,
  formData = false,
  service = ApiService
) => ({
  ...createGetService<R>(url, service),

  create(data: T) {
    return service.post<T, R>(url, data, formData);
  },

  update(id: string, data: T) {
    return service.patch<T, R>(`${url + id}/`, data, formData);
  },

  patch(data: T) {
    return service.patch<T, R>(url, data, formData);
  },

  delete(id: string) {
    return service.delete(`${url + id}/`);
  }
});

export type CRUDService = ReturnType<typeof createCRUDService>;
