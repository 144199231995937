import { DefaultUser } from '@ligo/shared/auth';
import { NotaryProductType } from './notary-product-types.model';
import { OnHoldReason } from './on-hold-reason.model';

export type NotaryProductExtra = {
  amount: number;
  description: string;
  id: string;
  name: string;
};

export type UuidObject = {
  uuid: string;
};

export enum NotaryProductDocumentKind {
  FIXED = 'fixed',
  GENERATED = 'generated',
  NOTARY_UPLOADED = 'notary_uploaded',
  NO_UPLOADED = 'no_uploaded',
  USER_UPLOADED = 'user_uploaded',
  LIGO_UPLOADED = 'ligo_uploaded'
}

export enum NotaryProductDocumentType {
  REGULAR = 'regular',
  FINAL_DEED = 'final_deed',
  DRAFT_DEED = 'draft_deed',
  KVK = 'kvk',
  POA = 'poa',
  PDC = 'pdc'
}

export enum NotaryProductEvent {
  SUBMIT = 'submit',
  CHECK_DOCUMENTS = 'check_documents',
  UPLOAD_FCA = 'upload_fca',
  SCHEDULE_MEETING = 'schedule_meeting',
  EXECUTE_DEED = 'execute_deed',
  EXTRACT_KVK = 'extract_kvk',
  FINAL = 'complete',
  CANCEL = 'cancel',
  REVERT = 'revert'
}

export enum NotaryProductStage {
  EXTRA_QUESTIONS = 'extra_questions',
  OPEN = 'open_mix',
  SUBMITTED = 'submitted_mix',
  CHECKING_DOCUMENTS = 'checking_documents',
  LS_CHECKING_DOCUMENTS = 'legal_support_checking_documents',
  UPLOADING_FCA = 'uploading_fca',
  SCHEDULING_MEETING = 'scheduling_meeting',
  EXECUTING_DEED = 'executing_deed',
  EXTRACTING_KVK = 'extracting_kvk',
  FINALIZED = 'finalized',
  ON_HOLD = 'on_hold',
  CANCELLED = 'canceled'
}

export enum NotaryProductDocumentAcceptance {
  UNCHECKED = 'unchecked',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected'
}

export interface AmendmentObject {
  name: string;
  amendment_request: string;
}
export interface Company {
  uuid: string;
  name: string;
  country: string;
  employee_count?: number;
  headquarters?: string;
  industry?: string;
  stage?: number;
  website?: string;
}
export interface LinkedProduct {
  id: number;
  uuid: string;
  name: string;
  alias: string;
  stage: NotaryProductStage;
  notary_product_type: NotaryProductType;
}

export interface NotaryProductDocument {
  uuid: string;
  name: string;
  alias: string;
  acceptance: NotaryProductDocumentAcceptance;
  kind: NotaryProductDocumentKind;
  document_type: NotaryProductDocumentType;
  downloadable_by_member: boolean;
  file_url: string;
  notary_product_id: string;
  status: boolean;
  notary_file_url?: string;
  vidyard_tutorial_id?: string;
  amendment_request?: string;
  accepted_at?: string;
  rejected_at?: string;
  updated_at?: string;
}

export interface Notary {
  city: string;
  firm: string;
  firstname: string;
  house_number: string;
  id: number;
  lastname: string;
  street: string;
  uuid: string;
  zip: string;
  email: string;
  phone: string;
  latitude: number;
  longitude: number;
  show_phonenumber: boolean;
  allow_video_calls: boolean;
  availability: NotaryAvailability;
  capacity_status: NotaryCapacityStatus;
  presentation_label?: string;
  terms_and_conditions: string;
}

export interface City {
  id: string;
  name: string;
}

export interface NotarySelection {
  city: string;
  id: number;
}

export interface NotaryProduct {
  uuid: string;
  name: string;
  priority: number;
  status: string;
  data_format: string;
  notary: Notary;
  company: Company;
  member: DefaultUser;
  description: string;
  notary_product_documents: NotaryProductDocument[];
  continue_link?: string;
  price?: number;
  signing_notary?: string;
  product_data?: any;
  upload_fca_sent_at: string;
  stage: NotaryProductStage;
  notary_product_type: NotaryProductType;
  submitted_mix_at: string;
  extra_questions_at: string;
  checking_documents_at: string;
  paid_at: string;
  payment_link: string;
  upsell_product: boolean;
  previous_on_hold_state: string;
  on_hold_reason: OnHoldReason;
  on_hold_description: string;
  on_hold_at: string;
  alias: string;
  linked_products: LinkedProduct[];
  notary_product_extras: NotaryProductExtra[];
  waiting_for_documents?: boolean;
  finalized_at?: string;
  checked_by_notary?: boolean;
  amendment_requests?: AmendmentObject[];
}

export interface Shareholder {
  address: string;
  zip_code: string;
  location: string;
  is_natural_person: boolean;
  is_new_company: boolean;
  is_existing_company: boolean;
  prefix: string;
  firstname: string;
  lastname: string;
  birthdate: string;
  birthplace: string;
  email: string;
  marital_status: string;
  lives_in_netherlands: boolean;
  english_proficient: boolean;
  become_director: boolean;
  dga_plan: boolean;
  holding: boolean;
  document_type: string;
  nationality: string;
  document_number: string;
  issue_date: string;
  expiration_date: string;
  place_of_issue: string;
  bsn: string;
  shares: string;
  holding_name: string;
  phone_number: string;
  has_proprietorship?: boolean;
  kvk_proprietorship?: number;
}

export interface NotaryProductReadDocsInterface {
  loadingDocs: boolean;
  docList: NotaryProductDocument[];
  readDoc: (doc: any, index: number) => void;
  downloadDoc: (doc: NotaryProductDocument) => void;
}

export enum NotaryProductNotaries {
  VG = 'vg-notarissen'
}

export enum NotaryAvailability {
  ONLINE = 'online',
  OFFLINE = 'offline',
  ONLINE_OFFLINE = 'online_offline'
}

export enum NotaryCapacityStatus {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high'
}

export enum BvType {
  STANDARD = 'standard',
  PRIVATE_SAVINGS = 'private_savings' // spaar bv
}
